import React, { FC, useEffect, useMemo, useState } from 'react';
import { identityService } from '../../services/identityService';
import { UserDetailPopup } from '../UserDetailsPopup/UserDetailPopup';
import { Field, FieldInputProps, FieldMetaProps, Form, Formik, FormikHelpers } from 'formik';
import { IdentityHookResult } from '../../hooks/useIdentity';
import { USER_DETAIL_POPUP_TYPE } from '../../types/user-detail-popup-type';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { Button, Grid, Stack, TextField, Typography } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { HtmlTooltip } from '../General/Reusable/HtmlTooltip';
import { TextFieldError } from '../General/TextFieldError';
import { GreyCheckbox } from '../General/Reusable/GreyCheckbox';
import { UserDetailValidationSchemaBuilder } from '../../util/userDetailValidationBuilder';
import GenderField from './GenderField';
import { getInitialUserDetailValues } from '../../util/formInitialValues';
import { getCountryConfig } from '../../util/getCountryConfig';
import { AddressServiceLanguage } from '../../services/addressService';
import { FormikAddressFrom } from '../Address/FormikAddressFrom';

interface UserDetailGridProps {
  identityHook: IdentityHookResult;
  editModeEnabled: boolean;
  setEditMode: React.Dispatch<React.SetStateAction<boolean>>;
  setSavingEditedUserData: React.Dispatch<React.SetStateAction<boolean>>;
}

export const UserDetailGrid: FC<UserDetailGridProps> = ({
  identityHook,
  editModeEnabled,
  setEditMode,
  setSavingEditedUserData,
}) => {
  const { identity, patchIdentity } = identityHook;
  const [country, setCountry] = useState<string>();
  const countryConfig = useMemo(() => {
    return getCountryConfig(country ?? identity?.address?.countryCode ?? '');
  }, [country, identity?.address?.countryCode]);

  const [sendNewVerificationMailPopup, setSendNewVerificationMailPopup] = useState<boolean>(false);
  const [userDetailPopupType, setUserDetailPopupType] = useState<
    USER_DETAIL_POPUP_TYPE | undefined
  >(undefined);
  const [systemBlockedTooltipMessage, setSystemBlockedTooltipMessage] = useState<string>(
    "If you suspect a user has been blocked by the system, you may unblock them by triggering the 'Change password' flow." +
      'They will receive an email that allows them to reset their password and log in afterwards.'
  );
  const [isSystemBlocked, setIsSystemBlocked] = useState<boolean>(false);

  const handleSendVerificationEmail = () => {
    setUserDetailPopupType(USER_DETAIL_POPUP_TYPE.VERIFY_EMAIL_REQUEST);
    setSendNewVerificationMailPopup(true);
  };

  useEffect(() => {
    async function getSystemBlockedTooltipMessage() {
      if (identity) {
        const blocksList = await identityService.getUserBlockList(identity.id);
        if (blocksList.length > 0) {
          setIsSystemBlocked(true);
          const message = `This user has been blocked for the following IP addresses: ${blocksList.map(
            (a) => `${a.ipAddress} `
          )}, you may unblock them by triggering the 'Change password' flow.
            They will receive an email that allows them to reset their password and log in afterwards."`;
          setSystemBlockedTooltipMessage(message);
        }
      }
    }

    getSystemBlockedTooltipMessage();
  }, [identity]);
  const validationProperties = useSelector((state: RootState) => state.validationProperties);

  const userEditValidationSchema = useMemo(() => {
    return new UserDetailValidationSchemaBuilder(validationProperties)
      .addFirstName()
      .addLastName()
      .addGender()
      .addNickName()
      .addBirthDay()
      .addAddress(AddressServiceLanguage.NL, countryConfig)
      .addTelephone()
      .build();
  }, [validationProperties, countryConfig]);

  const initialIdentityValues = useMemo(() => {
    return getInitialUserDetailValues(identity!);
  }, [identity]);

  const isSocialAccount = useMemo(
    () => identity?.id.startsWith('oauth2|NRCM|') || identity?.id.startsWith('google-oauth2|'),
    [identity]
  );

  const resetFormValues = (actions: FormikHelpers<any>) => {
    const fieldsToReset = {
      'namesPerson.firstName': initialIdentityValues.namesPerson?.firstName,
      'namesPerson.lastName': initialIdentityValues.namesPerson?.lastName,
      'namesPerson.nickName': initialIdentityValues.namesPerson?.nickName,
      'demographicsPerson.genderCode': initialIdentityValues.demographicsPerson?.genderCode,
      'demographicsPerson.genderCustom': initialIdentityValues.demographicsPerson?.genderCustom,
      'demographicsPerson.dateOfBirth': initialIdentityValues.demographicsPerson?.dateOfBirth,
      'address.street': initialIdentityValues.address?.street,
      'address.houseNumberExtension': identity?.address?.houseNumberExtension ?? '',
      'address.city': initialIdentityValues.address?.city,
      'address.countryCode': initialIdentityValues.address?.countryCode,
      'address.postalCode': initialIdentityValues.address?.postalCode,
      'contactpointsPhone.phone': initialIdentityValues.contactpointsPhone?.phone,
    };

    Object.entries(fieldsToReset).forEach(([field, value]) => {
      actions.setFieldValue(field, value);
    });
  };

  return (
    identity && (
      <Formik
        initialValues={initialIdentityValues}
        enableReinitialize
        onSubmit={(values, actions) => {
          setSavingEditedUserData(true);
          patchIdentity({ ...values })
            .then(() => {
              actions.setSubmitting(false);
              setEditMode(false);
            })
            .finally(() => {
              setSavingEditedUserData(false);
            });
        }}
        onReset={(values, actions) => {
          resetFormValues(actions);
          setEditMode(false);
        }}
        validationSchema={userEditValidationSchema}
        validateOnChange={true}>
        {(formik) => {
          return (
            <Form id="userDetailForm">
              <Grid container spacing={1}>
                <Grid item xs={4} display="flex">
                  <Typography variant="MHCaption" my="auto">
                    Auth0 ID
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <TextField
                    id="auth0Id"
                    inputProps={{ 'aria-label': 'Auth0 ID' }}
                    name="identity.id"
                    variant="outlined"
                    size="small"
                    className="white_outlined uneditable"
                    fullWidth
                    value={identity.id}
                    disabled
                  />
                </Grid>
                <Grid item xs={4} display="flex">
                  <Typography variant="MHCaption" my="auto">
                    Email address
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <TextField
                    id="email"
                    inputProps={{ 'aria-label': 'Email address' }}
                    name="identity.email"
                    variant="outlined"
                    size="small"
                    className="white_outlined uneditable"
                    fullWidth
                    value={identity.email}
                    disabled
                  />
                </Grid>
                <Grid item xs={4} display="flex">
                  <Typography variant="MHCaption" my="auto">
                    First name
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <Field name="namesPerson.firstName">
                    {({
                      field,
                      meta,
                    }: {
                      field: FieldInputProps<string>;
                      meta: FieldMetaProps<string>;
                    }) => (
                      <TextField
                        id="namesPerson.firstName"
                        inputProps={{ 'aria-label': 'First name' }}
                        variant="outlined"
                        size="small"
                        className="white_outlined"
                        fullWidth
                        required={false}
                        label={meta.value ? null : 'First name'}
                        error={!!meta.error}
                        helperText={<TextFieldError error={meta.error} />}
                        disabled={!editModeEnabled}
                        InputLabelProps={{ shrink: false }}
                        {...field}
                      />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={4} display="flex">
                  <Typography variant="MHCaption" my="auto">
                    Last name
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <Field name="namesPerson.lastName">
                    {({
                      field,
                      meta,
                    }: {
                      field: FieldInputProps<string>;
                      meta: FieldMetaProps<string>;
                    }) => (
                      <TextField
                        id="namesPerson.lastName"
                        inputProps={{ 'aria-label': 'Last name' }}
                        variant="outlined"
                        size="small"
                        className="white_outlined"
                        fullWidth
                        required={false}
                        label={meta.value ? null : 'Last name'}
                        error={!!meta.error}
                        helperText={<TextFieldError error={meta.error} />}
                        disabled={!editModeEnabled}
                        InputLabelProps={{ shrink: false }}
                        {...field}
                      />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={4} display="flex">
                  <Typography variant="MHCaption" my="auto">
                    Nickname
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <Field name="namesPerson.nickName">
                    {({
                      field,
                      meta,
                    }: {
                      field: FieldInputProps<string>;
                      meta: FieldMetaProps<string>;
                    }) => (
                      <TextField
                        id="namesPerson.nickName"
                        inputProps={{ 'aria-label': 'Nickname' }}
                        variant="outlined"
                        size="small"
                        className="white_outlined"
                        fullWidth
                        required={false}
                        label={meta.value ? null : 'Nickname'}
                        error={!!meta.error}
                        helperText={<TextFieldError error={meta.error} />}
                        disabled={!editModeEnabled}
                        InputLabelProps={{ shrink: false }}
                        {...field}
                      />
                    )}
                  </Field>
                </Grid>

                <GenderField
                  id={'demographicsPerson.genderCode'}
                  editModeEnabled={editModeEnabled}
                  formikProps={formik}
                />
                <Grid item xs={4} display="flex">
                  <Typography variant="MHCaption" my="auto">
                    Birthdate
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <Field name="demographicsPerson.dateOfBirth">
                    {({
                      field,
                      meta,
                    }: {
                      field: FieldInputProps<string>;
                      meta: FieldMetaProps<string>;
                    }) => (
                      <TextField
                        id="demographicsPerson.dateOfBirth"
                        inputProps={{ 'aria-label': 'Birthdate' }}
                        variant="outlined"
                        size="small"
                        className="white_outlined"
                        fullWidth
                        required={false}
                        label={meta.value ? null : 'Birthdate'}
                        error={!!meta.error}
                        helperText={<TextFieldError error={meta.error} />}
                        disabled={!editModeEnabled}
                        InputLabelProps={{ shrink: false }}
                        {...field}
                      />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={4} display="flex">
                  <Typography variant="MHCaption" my="auto">
                    Phone number
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <Field name="contactpointsPhone.phone">
                    {({
                      field,
                      meta,
                    }: {
                      field: FieldInputProps<string>;
                      meta: FieldMetaProps<string>;
                    }) => (
                      <TextField
                        id="contactpointsPhone.phone"
                        inputProps={{ 'aria-label': 'Phone number' }}
                        variant="outlined"
                        size="small"
                        className="white_outlined"
                        fullWidth
                        required={false}
                        label={meta.value ? null : 'Phone number'}
                        error={!!meta.error}
                        helperText={<TextFieldError error={meta.error} />}
                        disabled={!editModeEnabled}
                        InputLabelProps={{ shrink: false }}
                        {...field}
                      />
                    )}
                  </Field>
                </Grid>
                <FormikAddressFrom
                  formikProps={formik}
                  editModeEnabled={
                    false // TODO change to editModeEnabled once BFF points to IMS
                  }
                  onCountryChange={setCountry}
                />

                <Grid item xs={4} display="flex">
                  <Typography variant="MHCaption" my="auto">
                    Identity levels
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <Field name="identityLevels">
                    {({
                      field,
                      meta,
                    }: {
                      field: FieldInputProps<string>;
                      meta: FieldMetaProps<string>;
                    }) => (
                      <TextField
                        id="identityLevels"
                        inputProps={{ 'aria-label': 'Identity levels' }}
                        variant="outlined"
                        size="small"
                        className="white_outlined uneditable"
                        fullWidth
                        required={false}
                        label={meta.value ? null : 'Identity levels'}
                        error={!!meta.error}
                        helperText={<TextFieldError error={meta.error} />}
                        disabled
                        InputLabelProps={{ shrink: false }}
                        {...field}
                      />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={4} display="flex">
                  <Typography variant="MHCaption" my="auto">
                    Creation time
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <TextField
                    id="createdAt"
                    inputProps={{ 'aria-label': 'Creation time' }}
                    name="identity.createdAt"
                    variant="outlined"
                    size="small"
                    className="white_outlined uneditable"
                    fullWidth
                    value={
                      identity?.auditInfo?.creationDate &&
                      new Date(identity.auditInfo.creationDate).toLocaleString()
                    }
                    disabled
                  />
                </Grid>
                <Grid item xs={4} display="flex">
                  <Typography variant="MHCaption" my="auto">
                    Registered at
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <TextField
                    id="registeredAt"
                    inputProps={{ 'aria-label': 'Registered at' }}
                    name="identity.registeredAt"
                    variant="outlined"
                    size="small"
                    className="white_outlined uneditable"
                    fullWidth
                    value={identity.registeredAt}
                    disabled
                  />
                </Grid>
                <Grid item xs={4} display="flex">
                  <Typography variant="MHCaption" my="auto">
                    Signup language
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <TextField
                    id="signupLanguage"
                    inputProps={{ 'aria-label': 'Signup language' }}
                    name="identity.signupLanguage"
                    variant="outlined"
                    size="small"
                    className="white_outlined uneditable"
                    fullWidth
                    value={identity.signupLanguage}
                    disabled
                  />
                </Grid>
                <Grid item xs={4} display="flex">
                  <Typography variant="MHCaption" my="auto">
                    Last login
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <TextField
                    id="lastLogin"
                    inputProps={{ 'aria-label': 'Last login' }}
                    name="identity.lastLogin"
                    variant="outlined"
                    size="small"
                    className="white_outlined uneditable"
                    fullWidth
                    value={identity.lastLogin && new Date(identity.lastLogin).toLocaleString()}
                    disabled
                  />
                </Grid>
                <Grid item xs={4} display="flex">
                  <Typography variant="MHCaption" my="auto">
                    Verified email
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <Stack alignItems="center" direction={'row'} spacing={2}>
                    <GreyCheckbox
                      id="verifiedEmail"
                      disabled
                      label="Verified email"
                      name="identity.verifiedEmail"
                      checked={identity.emailVerified}
                    />
                    {!identity.emailVerified && (
                      <Button
                        variant="brandContained"
                        color="brandBlack"
                        size="small"
                        type="button"
                        disabled={isSocialAccount}
                        onClick={() => handleSendVerificationEmail()}>
                        Send new verification mail
                      </Button>
                    )}
                  </Stack>
                </Grid>

                {sendNewVerificationMailPopup && (
                  <UserDetailPopup
                    identityHook={identityHook}
                    showPopup={sendNewVerificationMailPopup}
                    popupClosed={setSendNewVerificationMailPopup}
                    popupType={userDetailPopupType}
                  />
                )}

                <Grid item xs={4} mt={0.5}>
                  <Typography variant="MHCaption" my="auto">
                    Blocked
                  </Typography>
                  <Typography variant="MHCaption2" my="auto">
                    (by system)
                  </Typography>
                </Grid>
                <Grid item xs={8} mt={0.5}>
                  <Stack alignItems="center" direction="row" spacing={2}>
                    <GreyCheckbox id="blocked" label="Blocked" checked={isSystemBlocked} disabled />
                    <HtmlTooltip placement="right" title={systemBlockedTooltipMessage}>
                      <InfoOutlinedIcon fontSize="small" fontWeight="small" />
                    </HtmlTooltip>
                  </Stack>
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    )
  );
};
