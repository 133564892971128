import { BrandConfig, BrandGroupConfig } from './brands';
import { IConsent } from './consent';
import { ILogin } from './login';
import { GenderCode } from './gender';

export enum IDENTITY_FIELDS {
  ID = 'id',
  EMAIL = 'email',
}

export interface NamesPerson {
  nickName?: string;
  firstName: string;
  lastName?: string;
}

export interface Address {
  street: string;
  houseNumber: string;
  houseNumberExtension: string;
  city: string;
  postalCode: string;
  countryCode: string;
}

export interface DemographicsPerson {
  genderCode: GenderCode;
  genderCustom?: string;
  dateOfBirth?: string;
}

export interface ContactpointsPhone {
  phone: string;
}

export interface AuditInfo {
  creationDate?: string;
  lastModificationDate?: string;
}

export interface FullIdentity {
  id: string;
  email: string;
  emailVerified?: boolean;
  namesPerson?: NamesPerson;
  demographicsPerson?: DemographicsPerson;
  contactpointsPhone?: ContactpointsPhone;
  address?: Address;
  identityLevels: string[];
  auditInfo?: AuditInfo;
  sub: string;
  logins?: ILogin[];
  consents?: IConsent[];
  registeredAt: string;
  signupLanguage?: string;
  lastLogin?: string;
}

export interface Identity {
  id: string;
  email: string;
  emailVerified: boolean;
  namesPerson?: NamesPerson;
  demographicsPerson?: DemographicsPerson;
  contactpointsPhone?: ContactpointsPhone;
  address?: Address;
  identityLevels: string[];
}

export interface CreateIdentityRequestIdentity {
  email: string; // required
  namesPerson?: Partial<NamesPerson>;
  demographicsPerson?: Partial<DemographicsPerson>;
  contactpointsPhone?: Partial<ContactpointsPhone>;
  address?: Partial<Address>;
}

export interface CreateIdentityOptions {
  language?: string;
}

export interface CreateIdentityRequest {
  identity: CreateIdentityRequestIdentity;
  options?: CreateIdentityOptions;
}
